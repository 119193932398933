import React from "react";
import { Link } from "react-router-dom";
import "../../styles/Footer.css";
import { MDBIcon } from "mdb-react-ui-kit";

const ListLink = ({ to, value }) => (
  <li>
    <Link to={to} style={{ color: "#bbb" }}>
      {value}
    </Link>
  </li>
);

export default function App() {
  const color = "#ff7f23";
  // const color = "#3b71ca";
  return (
    <div className="footer text-center">
      <div className="footerLinks">
        <div className="footerContact text-center">
          <h5>Need Help</h5>
          <p className="contact">{`+91 ${process.env.REACT_APP_CONTACT_NO}`}</p>
          <p>Monday - Friday: 9:00-20:00</p>
          <p>Saturday: 11:00 - 15:00</p>
          <h6>
            <Link to="/contact" style={{ color: "#bbb" }}>
              {`${process.env.REACT_APP_EMAIL}`}
            </Link>
          </h6>
          <div
            className="socialMedia"
            style={{ marginTop: "10px", fontSize: "18px" }}
          >
            <Link to={"#"} style={{ padding: "10px", color: color }}>
              <MDBIcon fab icon="facebook-f" />
            </Link>
            <Link to={"#"} style={{ padding: "10px", color: color }}>
              <MDBIcon fab icon="twitter" />
            </Link>
            <Link to={"#"} style={{ padding: "10px", color: color }}>
              <MDBIcon fab icon="instagram" />
            </Link>
            <Link to={"#"} style={{ padding: "10px", color: color }}>
              <MDBIcon fab icon="youtube" />
            </Link>
            <Link to={"#"} style={{ padding: "10px", color: color }}>
              <MDBIcon fab icon="pinterest" />
            </Link>
          </div>
        </div>
        <div className="footerLink1">
          <ul>
            <li>
              <Link to="/contact" style={{ color: color }}>
                Useful Links
              </Link>
            </li>
            <ListLink to={"/privacypolicy"} value="Privacy Policy" />
            <ListLink to={"/returnpolicy"} value="Returns Policy" />
            <ListLink to={"/termsandconditions"} value="Terms & Conditions" />
            <ListLink to={"/contact"} value="Contact Us" />
          </ul>
        </div>

        <div className="footerLink3">
          <ul>
            <li>
              <Link to="/contact" style={{ color: color }}>
                My Account
              </Link>
            </li>
            <ListLink to={"/profile"} value="My Account" />
            <ListLink to={"/profile"} value="Order" />
          </ul>
        </div>
      </div>

      <br />
      <br />
      <div className="text-center h4 p-3">
        Copyright ©2023. All Rights Reserved
      </div>
    </div>
  );
}
