import React, { useState } from "react";
import { useCartContext } from "../../context/cartcontext";
// import { useUserContext } from "../../context/usercontext";
import "../../styles/Navbar.css";
import { MDBBadge, MDBIcon } from "mdb-react-ui-kit";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { cart, clearCart } = useCartContext();
  const [navBar, setNavBar] = useState(false);

  const handleNavBar = () => {
    setNavBar(!navBar);
    document.querySelector(".navBar").classList.toggle("active");
  };

  return (
    <header>
      <div className="topBar">
        <div className="subSection d-flex justify-content-center align-items-center">
          <div className="brandName">{process.env.REACT_APP_COMPANY_NAME}</div>
          <div className="extras">
            <div className="extrasBox">
              <div className="extrasLogo">
                <MDBIcon fas icon="money-bill" />
              </div>
              <div className="extrasText">
                <span>COD Available</span>
                <span>Assured Delivery</span>
              </div>
            </div>
          </div>
        </div>
        <div className="functional">
          <span>
            <Link to="/profile">
              <MDBIcon
                far
                icon="user-circle"
                style={{ color: "#333", marginLeft: "20px" }}
              />
            </Link>
          </span>

          <span>
            <Link to="/orderbyprescription">
              <MDBIcon
                far
                icon="file-alt"
                style={{ color: "#333", marginLeft: "20px" }}
              />
            </Link>
          </span>

          <span>
            <Link to="/cart">
              <MDBIcon
                fas
                icon="shopping-cart"
                style={{ color: "#333", marginLeft: "20px" }}
              />
              <MDBBadge
                color="danger"
                notification
                pill
                style={{ fontSize: "10px" }}
              >
                {cart.length}
              </MDBBadge>
            </Link>
          </span>

          {!localStorage.getItem("authToken") ? (
            <></>
          ) : (
            <span>
              <Link
                reloadDocument
                to="/"
                className="navbar-link"
                onClick={() => {
                  localStorage.clear();
                  // window.location.reload();
                  clearCart();
                }}
              >
                <MDBIcon
                  fas
                  icon="sign-out-alt"
                  style={{ color: "#333", marginLeft: "20px" }}
                />
              </Link>
            </span>
          )}
          <span className="menuBar" onClick={handleNavBar}>
            {navBar === true ? (
              <MDBIcon
                fas
                icon="xmark"
                style={{ color: "#333", marginLeft: "20px" }}
              />
            ) : (
              <MDBIcon
                fas
                icon="bars"
                style={{ color: "#333", marginLeft: "20px" }}
              />
            )}
          </span>
        </div>
      </div>
      <div className="navBar">
        <ul>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/products">Shop</Link>
          </li>

          {navBar === true ? (
            <>
              <li>
                <Link to="/profile">
                  <MDBIcon
                    far
                    icon="user-circle"
                    style={{ color: "#fff", marginLeft: "20px" }}
                  />
                </Link>
              </li>
              <li>
                <Link to="/cart">
                  <MDBIcon
                    fas
                    icon="shopping-cart"
                    style={{ color: "#fff", marginLeft: "20px" }}
                  />
                  <MDBBadge
                    color="danger"
                    notification
                    pill
                    style={{ fontSize: "10px" }}
                  >
                    {cart.length}
                  </MDBBadge>
                </Link>
              </li>
            </>
          ) : (
            <></>
          )}
        </ul>
      </div>
    </header>
  );
};

export default Navbar;
