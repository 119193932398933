import React from "react";
import "./styles/Returnpolicy.css";
import { MDBIcon } from "mdb-react-ui-kit";
import imgText from "./utils/shipmentImg.jpg";
import HeroSection from "./styles/HeroSection";

const ReturnPolicy = () => {
  return (
    <div>
      <HeroSection page="Return Policy" />
      {/* <div className="Contact">
        <div className="ContactText">
          <h2>Return Policy</h2>
        </div>
      </div> */}
      <div className="lastSection">
        {/* <span className="shipmentLogo">
          <MDBIcon fas icon="truck" />
        </span> */}
        {/* <div className="ReturnPolicy"> */}

        <div className="shipmentText">
          <h2>WE DELIVER THE BEST QUALITY</h2>
          <br />
          <p>
            Thank you for choosing{" "}
            <strong>{process.env.REACT_APP_COMPANY_NAME}</strong> for your
            healthcare needs. Please review our return policy below:
          </p>
          <br />
          <br />

          <h3>1. Returns Eligibility</h3>
          <ul>
            <li>
              <span>&#8226; </span>We accept returns within 7 days from the date
              of purchase.
            </li>
            <li>
              <span>&#8226; </span>To be eligible for a return, the item must be
              unused, in its original packaging, and in the same condition as
              received.
            </li>
          </ul>

          <h3>2. Non-Returnable Items</h3>
          <ul>
            <li>
              <span>&#8226; </span>Prescription medications cannot be returned
              due to legal and safety reasons.
            </li>
            <li>
              <span>&#8226; </span>Opened or used items cannot be returned
              unless they are defective or damaged upon arrival.
            </li>
          </ul>

          <h3>3. Return Process</h3>
          <ul>
            <li>
              <span>&#8226; </span>Contact our customer service team at{" "}
              <strong>{process.env.REACT_APP_EMAIL}</strong> to initiate a
              return.
            </li>
            <li>
              <span>&#8226; </span>Provide your order number, the name of the
              item(s) you wish to return, and the reason for the return.
            </li>
            <li>
              <span>&#8226; </span>Our team will guide you on the return
              procedure, including any necessary documentation.
            </li>
          </ul>

          <h3>4. Refunds</h3>
          <ul>
            <li>
              <span>&#8226; </span>Once your return is received and inspected,
              we will notify you of the approval or rejection of your refund.
            </li>
            <li>
              <span>&#8226; </span>If approved, refunds will be processed to the
              original method of payment within [number] business days.
            </li>
          </ul>

          <h3>5. Shipping Costs</h3>
          <ul>
            <li>
              <span>&#8226; </span>Customers are responsible for return shipping
              costs, except in cases where the return is due to our error or a
              defective product.
            </li>
          </ul>

          <h3>6. Damaged or Defective Items</h3>
          <ul>
            <li>
              <span>&#8226; </span>If you receive a damaged or defective item,
              please contact us immediately.
            </li>
            <li>
              <span>&#8226; </span>We may require photographic evidence of the
              damage or defect to expedite the return process.
            </li>
          </ul>

          <h3>7. Exchanges</h3>
          <ul>
            <li>
              <span>&#8226; </span>We do not offer exchanges at this time. You
              may return your item(s) for a refund and place a new order.
            </li>
          </ul>

          <h3>8. Cancellations</h3>
          <ul>
            <li>
              <span>&#8226; </span>Orders can be cancelled before they are
              shipped. Once shipped, the standard return policy applies.
            </li>
          </ul>

          <div className="disclaimer">
            <p>
              <strong>Note:</strong> This return policy is subject to change
              without prior notice. Please refer to our website for the most
              current policy. Thank you for choosing{" "}
              <strong>{process.env.REACT_APP_COMPANY_NAME}</strong>. We
              appreciate your business and look forward to serving you again.
            </p>

            {/* <p>
                    Thank you for choosing <strong>Ashadeep Medcare</strong>. We appreciate your business and look forward to serving you again.
                </p> */}
          </div>
        </div>
        {/* </div> */}
        {/* <div
          className="textImage"
          style={{ borderLeft: "15px solid #ff7f23", paddingLeft: "10px" }}
        >
          <img src={imgText} alt="contactImg" />
        </div> */}
      </div>
    </div>
  );
};

export default ReturnPolicy;
