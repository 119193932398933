import React from "react";
import "./styles/Contact.css";
import { MDBIcon } from "mdb-react-ui-kit";
import HeroSection from "./styles/HeroSection";

const Contact = () => {
  return (
    <div>
      <HeroSection page="Contact Us" />
      <div className="Contact">
        <div className="ContactText">
          <h4>Contact With Us</h4>
          <h2>Speak with our Expert.</h2>
          <br />
          <br />
          <div className="Extras">
            <div className="Logo">
              <MDBIcon fas icon="phone" />
            </div>
            <div className="Text">
              <h5 className="text-muted">CALL ANYTIME</h5>
              <h3>+91 {process.env.REACT_APP_CONTACT_NO}</h3>
            </div>
          </div>
          <div className="Extras">
            <div className="Logo">
              <MDBIcon far icon="envelope" />
            </div>
            <div className="Text">
              <h5 className="text-muted">SEND EMAIL</h5>
              <h3>{process.env.REACT_APP_EMAIL}</h3>
            </div>
          </div>
          <div className="Extras">
            <div className="Logo">
              <MDBIcon fas icon="map-marker-alt" />
            </div>
            <div className="Text">
              <h5 className="text-muted">VISIT OFFICE</h5>
              <h3>{process.env.REACT_APP_ADDRESS}</h3>
            </div>
          </div>
        </div>
        <form className="ContactForm">
          <input type="text" value="" placeholder="Name" />
          <input type="text" value="" placeholder="Email" />
          <br />
          <input type="text" value="" placeholder="Phone" />
          <input type="text" value="" placeholder="Subject" />
          <br />
          <textarea placeholder="Your Message"></textarea>
          <br />
          <input type="submit" value="Send Message" />
        </form>
      </div>
    </div>
  );
};

export default Contact;
